import { ZEME_USER_DATA } from '@src/constants/localStorage';
import { SessionObj } from '@src/interfaces/utils/localStorage';

/**
 * Get an item from local storage.
 * @param key - The key of the item to retrieve.
 * @returns The retrieved item of type T, or null if the item doesn't exist or an error occurs.
 */
export function getItem<T>(key: string): T | null {
  try {
    const serializedValue = localStorage.getItem(key);
    if (serializedValue !== null) {
      return isSerializedJSON(serializedValue)
        ? JSON.parse(serializedValue)
        : serializedValue;
    }
  } catch (error) {
    console.error(`Error getting item from local storage: ${error}`);
  }
  return null;
}

/**
 * Set an item in local storage.
 * @param key - The key of the item to set.
 * @param value - The value of the item to set, either an object or a string.
 */
export function setItem(key: string, value: object | string): void {
  try {
    const serializedValue =
      typeof value === 'object' ? JSON.stringify(value) : value;
    localStorage.setItem(key, serializedValue);
  } catch (error) {
    console.error(`Error setting item in local storage: ${error}`);
  }
}

/**
 * Removes Item from Local Storage
 * @param key
 */
export function removeItem(key: string): void {
  try {
    localStorage.removeItem(key);
  } catch (error) {
    console.error(`Error removing item from local storage: ${error}`);
  }
}

/**
 * Checks User is Loggedin or Not
 * @returns A boolean indicating whether the user is logged in or not.
 */
export function isLoggedIn(): boolean {
  try {
    const storedValue = getItem<SessionObj | null>(ZEME_USER_DATA);
    return storedValue?.account_type
      ? Boolean(storedValue.account_type)
      : false;
  } catch (error) {
    console.error(`Error checking if user is logged in: ${error}`);
    return false;
  }
}

/**
 * Returns access token
 */
export function getAccessToken(): string | null {
  try {
    const storedValue = getItem<SessionObj | null>(ZEME_USER_DATA);
    return storedValue?.access_token ? storedValue?.access_token : null;
  } catch (error) {
    console.error(`Error checking if user is logged in: ${error}`);
    return null;
  }
}

/**
 * Returns access token
 */
export function getRefreshToken(): string | null {
  try {
    const storedValue = getItem<SessionObj | null>(ZEME_USER_DATA);
    return storedValue?.refresh_token ? storedValue?.refresh_token : null;
  } catch (error) {
    console.error(`Error checking if user is logged in: ${error}`);
    return null;
  }
}

/**
 * Checks if Valid JSON
 * @param value
 * @returns A boolean value indication whether the string is serialisable
 */
function isSerializedJSON(value: string): boolean {
  try {
    JSON.parse(value);
    return true;
  } catch (error) {
    return false;
  }
}

/**
 * Retrieves logged in users id
 * @returns User Id of logged in user
 */
export function getUserId(): string | null {
  try {
    const storedValue = getItem<SessionObj | null>(ZEME_USER_DATA);
    return storedValue?.id ? storedValue?.id : null;
  } catch (error) {
    console.error(`Error getting logged in user id: ${error}`);
    return null;
  }
}

/**
 * Retrieves logged in users id
 * @returns User Id of logged in user
 */
export function getRelatedAgentId(): string | null {
  try {
    const storedValue = getItem<SessionObj | null>(ZEME_USER_DATA);
    return storedValue?.related_agent_id ? storedValue?.related_agent_id : null;
  } catch (error) {
    console.error(`Error getting logged in user id: ${error}`);
    return null;
  }
}

/**
 * Retrieves logged in users type renter|agent
 * @returns Account type of logged in user
 */
export function getUserType(): string {
  try {
    const storedValue = getItem<SessionObj | null>(ZEME_USER_DATA);
    return storedValue?.account_type
      ? storedValue?.account_type?.toLowerCase()
      : '';
  } catch (error) {
    console.error(`Error getting logged in user id: ${error}`);
    return '';
  }
}

/**
 * Retrieves the verification status of the user.
 *
 * @return {boolean} The verification status of the user. Returns `true` if the user is verified, `false` otherwise.
 */
export function getUserVerified(): boolean {
  try {
    const storedValue = getItem<SessionObj | null>(ZEME_USER_DATA);
    return storedValue?.verified ? storedValue?.verified : false;
  } catch (error) {
    console.error(`Error getting logged in user id: ${error}`);
    return false;
  }
}

/**
 * Retrieves whether the user has ever subscribed.
 *
 * @return {boolean} The value indicating whether the user has ever subscribed.
 */
export function getUserEverSubscribed(): boolean {
  try {
    const storedValue = getItem<SessionObj | null>(ZEME_USER_DATA);
    return storedValue?.ever_subscribed ? storedValue?.ever_subscribed : false;
  } catch (error) {
    console.error(`Error getting logged in user id: ${error}`);
    return false;
  }
}

export function getSavedPropertyIds(): string[] {
  try {
    const isSavedPropertyId: string[] = getItem('savedPropertyId') ?? [];
    return isSavedPropertyId;
  } catch (error) {
    console.error(error);
    return [];
  }
}

export const setSavedPropertyId = (propertyId: string) => {
  try {
    const getPropertyIds = getSavedPropertyIds();
    if (!getPropertyIds.includes(propertyId)) {
      getPropertyIds.push(propertyId);
    }
    const savedPropertyId = JSON.stringify(getPropertyIds);
    setItem('savedPropertyId', savedPropertyId);
  } catch (error) {
    console.error(error);
  }
};

export const removeSavedPropertyId = (propertyId: string) => {
  try {
    const getPropertyIds = getSavedPropertyIds();

    if (getPropertyIds.includes(propertyId)) {
      const removedIds = getPropertyIds.filter((item) => item !== propertyId);
      const savedPropertyId = JSON.stringify(removedIds);
      setItem('savedPropertyId', savedPropertyId);
    }
  } catch (error) {
    console.error(error);
  }
};
