import React from 'react';

function RenterIcon(props: object) {
  return (
    <svg
      width="138"
      height="76"
      viewBox="0 0 138 76"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        opacity="0.8"
        d="M55.9475 53.8755H9.54688V75.2745H55.9475V53.8755Z"
        fill="#CFDFFF"
      />
      <path
        opacity="0.8"
        d="M0 53.8755L9.8125 45.1846H60.2473L56.6045 53.8755H0Z"
        fill="#90B2E6"
      />
      <path
        opacity="0.8"
        d="M56.7652 22.1579H13.8994V49.6489H56.7652V22.1579Z"
        fill="#CFDFFF"
      />
      <path
        opacity="0.8"
        d="M43.5709 28.4299H26.5098V40.8761H43.5709V28.4299Z"
        fill="#B7CCF5"
      />
      <path
        opacity="0.8"
        d="M35.6319 28.4299H34.2393V40.8761H35.6319V28.4299Z"
        fill="#90B2E6"
      />
      <path
        opacity="0.8"
        d="M43.5709 34.1791H26.5098V35.19H43.5709V34.1791Z"
        fill="#90B2E6"
      />
      <path
        opacity="0.8"
        d="M43.5709 27.3088H26.5098V28.4299H43.5709V27.3088Z"
        fill="#90B2E6"
      />
      <path
        opacity="0.8"
        d="M26.5099 27.3088H24.9629V40.876H26.5099V27.3088Z"
        fill="#90B2E6"
      />
      <path
        opacity="0.8"
        d="M45.2192 27.3088H43.5713V40.876H45.2192V27.3088Z"
        fill="#90B2E6"
      />
      <path
        opacity="0.8"
        d="M45.219 40.876H24.9629V42.199H45.219V40.876Z"
        fill="#90B2E6"
      />
      <path
        opacity="0.8"
        d="M67.7944 14.4554L57.4526 21.8932C57.2135 22.0652 56.9264 22.1579 56.6319 22.158H14.673C13.271 22.158 12.7328 20.3304 13.9112 19.5709L43.9145 0.224625C44.1251 0.0886911 44.3681 0.0115492 44.6185 0.00119892C44.8689 -0.00915139 45.1175 0.0476658 45.3386 0.16576L64.1539 10.215L67.6359 12.0748C67.8465 12.1874 68.0249 12.3516 68.1543 12.5522C68.2838 12.7528 68.36 12.983 68.3759 13.2212C68.3917 13.4593 68.3467 13.6976 68.2449 13.9136C68.1432 14.1295 67.9882 14.316 67.7944 14.4554V14.4554Z"
        fill="#A8CBF3"
      />
      <path
        opacity="0.8"
        d="M50.1992 55.7737H12.2129V75.2744H50.1992V55.7737Z"
        fill="#E9F0FD"
      />
      <path
        opacity="0.8"
        d="M50.1992 55.7737H12.2129V56.3558H50.1992V55.7737Z"
        fill="#A1BDF4"
      />
      <path
        opacity="0.8"
        d="M50.1992 57.1165H12.2129V57.6985H50.1992V57.1165Z"
        fill="#A1BDF4"
      />
      <path
        opacity="0.8"
        d="M50.1992 58.9066H12.2129V59.4885H50.1992V58.9066Z"
        fill="#A1BDF4"
      />
      <path
        opacity="0.8"
        d="M50.1992 60.697H12.2129V61.279H50.1992V60.697Z"
        fill="#A1BDF4"
      />
      <path
        opacity="0.8"
        d="M50.1992 62.9346H12.2129V63.5166H50.1992V62.9346Z"
        fill="#A1BDF4"
      />
      <path
        opacity="0.8"
        d="M50.1992 65.1721H12.2129V65.7542H50.1992V65.1721Z"
        fill="#A1BDF4"
      />
      <path
        opacity="0.8"
        d="M50.1992 67.41H12.2129V67.992H50.1992V67.41Z"
        fill="#A1BDF4"
      />
      <path
        opacity="0.8"
        d="M50.1992 69.6478H12.2129V70.2297H50.1992V69.6478Z"
        fill="#A1BDF4"
      />
      <path
        opacity="0.8"
        d="M50.1992 71.8855H12.2129V72.4674H50.1992V71.8855Z"
        fill="#A1BDF4"
      />
      <path
        opacity="0.8"
        d="M56.7652 22.1579H13.8994V25.2605H56.7652V22.1579Z"
        fill="#BECEF8"
      />
      <path
        opacity="0.8"
        d="M50.6183 74.583H11.793V55.3927H50.6183V74.583ZM12.6321 73.8217H49.7792V56.1541H12.6321V73.8217Z"
        fill="#90B2E6"
      />
      <path
        opacity="0.8"
        d="M55.1816 23.7469L48.4912 49.6488H55.1816V23.7469Z"
        fill="#BECEF8"
      />
      <path
        opacity="0.8"
        d="M84.0527 23.7469H55.1816V75.2746H84.0527V23.7469Z"
        fill="#DCE6FF"
      />
      <path
        opacity="0.8"
        d="M84.9647 48.7911H54.1504V51.2379H84.9647V48.7911Z"
        fill="#A8CBF3"
      />
      <path
        opacity="0.8"
        d="M78.0973 29.2607H61.0361V41.7068H78.0973V29.2607Z"
        fill="#B7CCF5"
      />
      <path
        opacity="0.8"
        d="M70.1573 29.2607H68.7646V41.7068H70.1573V29.2607Z"
        fill="#90B2E6"
      />
      <path
        opacity="0.43"
        d="M78.0973 35.0099H61.0361V36.0207H78.0973V35.0099Z"
        fill="#90B2E6"
      />
      <path
        opacity="0.8"
        d="M78.0973 28.1396H61.0361V29.2607H78.0973V28.1396Z"
        fill="#90B2E6"
      />
      <path
        opacity="0.8"
        d="M61.0364 28.1396H59.4893V41.7068H61.0364V28.1396Z"
        fill="#90B2E6"
      />
      <path
        opacity="0.8"
        d="M79.7447 28.1396H78.0967V41.7068H79.7447V28.1396Z"
        fill="#90B2E6"
      />
      <path
        opacity="0.8"
        d="M79.7456 41.7068H59.4893V43.0298H79.7456V41.7068Z"
        fill="#90B2E6"
      />
      <path
        opacity="0.8"
        d="M85.9555 47.0638H52.96V49.5108H85.9555V47.0638Z"
        fill="#A1BDF4"
      />
      <path
        opacity="0.8"
        d="M67.7945 14.4554L57.4526 21.8931C57.2135 22.0652 56.9265 22.1578 56.6319 22.158H49.2236C54.2565 18.2481 59.2332 14.2671 64.154 10.215L67.636 12.0748C67.8465 12.1873 68.0249 12.3516 68.1544 12.5522C68.2838 12.7527 68.3601 12.9829 68.376 13.2211C68.3918 13.4593 68.3467 13.6976 68.245 13.9135C68.1433 14.1295 67.9882 14.316 67.7945 14.4554Z"
        fill="#A1BDF4"
      />
      <path
        opacity="0.8"
        d="M55.1816 23.747L69.4586 10.4333L84.0527 23.747H55.1816Z"
        fill="#A8CBF3"
      />
      <path
        opacity="0.8"
        d="M84.0527 23.7469H55.1816V26.051H84.0527V23.7469Z"
        fill="#BECEF8"
      />
      <path
        opacity="0.8"
        d="M85.9555 47.0638H52.96V48.2873H85.9555V47.0638Z"
        fill="#90B2E6"
      />
      <path
        opacity="0.8"
        d="M84.052 74.2025H9.54688V75.2746H84.052V74.2025Z"
        fill="#A1BDF4"
      />
      <path
        opacity="0.8"
        d="M81.599 41.9807H57.876V43.0641H81.599V41.9807Z"
        fill="#F1F3FF"
      />
      <path
        opacity="0.8"
        d="M81.599 46.549H57.876V47.6324H81.599V46.549Z"
        fill="#F1F3FF"
      />
      <path
        opacity="0.8"
        d="M59.8958 43.0641H58.6006V47.0198H59.8958V43.0641Z"
        fill="#F1F3FF"
      />
      <path
        opacity="0.8"
        d="M63.2522 43.0641H61.957V47.0198H63.2522V43.0641Z"
        fill="#F1F3FF"
      />
      <path
        opacity="0.8"
        d="M66.6097 43.0641H65.3145V47.0198H66.6097V43.0641Z"
        fill="#F1F3FF"
      />
      <path
        opacity="0.8"
        d="M69.9661 43.0641H68.6709V47.0198H69.9661V43.0641Z"
        fill="#F1F3FF"
      />
      <path
        opacity="0.8"
        d="M73.3225 43.0641H72.0273V47.0198H73.3225V43.0641Z"
        fill="#F1F3FF"
      />
      <path
        opacity="0.8"
        d="M77.5178 43.0641H76.2227V47.0198H77.5178V43.0641Z"
        fill="#F1F3FF"
      />
      <path
        opacity="0.8"
        d="M80.8743 43.0641H79.5791V47.0198H80.8743V43.0641Z"
        fill="#F1F3FF"
      />
      <rect x="62" y="55" width="11" height="19.2431" fill="#C9DBFF" />
      <circle cx="63.5" cy="62.5" r="0.5" fill="#E6EEFE" />
      <rect x="63" y="64" width="1" height="2" fill="#E6EEFE" />
      <path
        d="M87.9942 74.8947C92.1974 75.5825 96.1833 72.828 96.8916 68.746C97.5998 64.6643 94.7635 60.7929 90.5606 60.1051C87.069 59.5338 83.7339 61.3417 82.2965 64.3091C76.8758 63.4483 71.4551 62.5878 66.0344 61.727C65.6922 64.3407 67.952 66.9921 70.5214 67.8366C70.4595 67.3381 71.0339 66.6952 71.5446 66.617C72.0553 66.5388 72.5954 66.9051 72.6949 67.3978C72.9343 66.8964 73.634 66.7477 74.1849 66.8864C74.7358 67.025 75.1429 67.577 75.1007 68.128C77.1729 68.9266 79.4773 69.184 81.7046 68.9284C82.3025 71.9074 84.7325 74.3611 87.9942 74.8947ZM94.428 68.3431C94.2114 69.592 92.9933 70.4337 91.7076 70.2234C90.4149 70.012 89.5482 68.8289 89.7648 67.58C89.9813 66.331 91.1995 65.4893 92.4923 65.7011C93.7783 65.9114 94.645 67.0941 94.428 68.3431Z"
        fill="#93B4E8"
      />
      <path
        d="M103.707 62.6613C101.15 61.5514 99.4288 59.0482 96.9316 57.8087C94.9863 56.8429 92.6333 56.7359 90.6083 57.5213C88.9173 58.1771 87.4269 59.5045 86.8807 61.2341C86.1575 63.5242 87.2538 66.1329 89.1412 67.6185C91.0281 69.1041 93.548 69.5938 95.9454 69.4509C98.3429 69.3081 100.664 68.5884 102.956 67.8726C103.834 66.2424 102.84 64.2967 103.707 62.6613Z"
        fill="#E3EBFF"
      />
      <path
        d="M137.345 59.3556C137.345 59.3556 127.777 61.0989 103.235 58.6087C103.235 58.6087 99.9604 66.4089 101.116 72.1869C101.116 72.1869 127.295 76.156 137.345 74.3287V59.3556Z"
        fill="#AAC7F4"
      />
    </svg>
  );
}

export default RenterIcon;
