// Icons
import Home from '@src/assets/svg/home';
import Search from '@src/assets/svg/search';
// import Bell from '@src/assets/svg/bell';
import Messages from '@src/assets/svg/messages';
// import Analytics from '@src/assets/svg/analytics';
import SavedListing from '@src/assets/svg/savedListing';
// import ProfileEdit from '@src/assets/svg/profileEdit';
import MyApplication from '@src/assets/svg/myApplication';
import { getUserType } from '@src/utils/localStorage';
import { ZEME_USERS_ROLE } from './configs';

export const APP_ROUTE = {
  HOME: '/',
  PUBLIC_PROPERTIES: '/properties',
  EXPLORE: '/explore',
  AGENT_HOME: '/agent/dashboard',
  ANALYTICS: '/analytics',
  SAVED_LISTING: '/saved-listing',
  RENTER_REGISTER: '/auth/renter/register',
  RENTER_REGISTER_PAGE_2: '/register',
  AGENT_REGISTER: '/auth/agent/register',
  LANDLORD_REGISTER: '/auth/landlord/register',
  LOGIN: '/auth/login',
  SETTINGS: '/settings/edit-profile',
  GENERAL_SETTINGS: '/settings/general',
  SUBSCRIPTION_SETTINGS: '/settings/subscription',
  FORGOT_PASSWORD: '/forgot-password',
  VERIFY_CODE: '/auth/verification-code',
  SUBSCRIPTION: '/auth/subscribe',
  MANUAL_VERIFICATION: '/auth/verification-required',
  AI_MARKETING_SETTINGS: '/settings/ai-marketing',

  // Agent routes
  AGENT_DASHBOARD: '/agent/dashboard',
  AGENT_DASHBOARD_CRM: '/agent/dashboard/crm/application',
  AGENT_DASHBOARD_CRM_MARKETING: '/agent/dashboard/crm/marketing',
  AGENT_ADD_PROPERTY: '/agent/#ID#/add-property',
  AGENT_EXPLORE: '/agent/explore',
  AGENT_MESSAGES: '/agent/chat',
  AGENT_NOTIFICATIONS: '/agent/notifications',
  AGENT_ADD_NEW_PROPERTY: '/property/add',
  AGENT_EDIT_PROPERTY: '/property/#ID#/edit',
  AGENT_MY_APPLICATION: '/agent/property/#PROPERTY_ID#/my-application',
  AGENT_CHAT_ROOM: '/agent/chat/#ROOM_ID#/#PROPERTY_ID#',
  AGENT_CRM_CHAT_ROOM: '/agent/chat/#ROOM_ID#',
  AGENT_SEND_LEASE_MY_APPLICATION:
    '/agent/property/#PROPERTY_ID#/my-application/#APPLICATION_ID#',
  AGENT_PUBLIC_PROFILE: '/agent/profile/#ID#',
  AGENT_PROFILE: '/agent/profile/#USER_IDENTIFIER#',

  // Renter routes
  RENTER_MY_APPLICATION: '/renter/my-application',
  RENTER_MY_APPLICATION_ID: '/renter/my-application/#APPLICATION_ID#',
  RENTER_MESSAGES: '/renter/chat',
  RENTER_CHAT_ROOM: '/renter/chat/#ROOM_ID#/#PROPERTY_ID#',

  // Privacy & terms
  PRIVACY: '/privacy-policy',
  TERMS: '/terms',
  TU_RENTER_TERMS: '/transunion/renter',
  TU_AGENT_TERMS: '/transunion/agent',
};

export const RENTER_ACCESS_ROUTES = [
  APP_ROUTE.EXPLORE,
  APP_ROUTE.RENTER_MY_APPLICATION,
  APP_ROUTE.RENTER_MY_APPLICATION_ID,
  APP_ROUTE.RENTER_MESSAGES,
  APP_ROUTE.RENTER_CHAT_ROOM,
  APP_ROUTE.VERIFY_CODE,
  APP_ROUTE.SETTINGS,
  APP_ROUTE.GENERAL_SETTINGS,
  APP_ROUTE.AGENT_PUBLIC_PROFILE,
  // APP_ROUTE.SAVED_LISTING,
];

export const AGENT_ACCESS_ROUTES = [
  APP_ROUTE.EXPLORE,
  APP_ROUTE.AGENT_DASHBOARD,
  APP_ROUTE.AGENT_DASHBOARD_CRM,
  APP_ROUTE.AGENT_DASHBOARD_CRM_MARKETING,
  APP_ROUTE.AGENT_ADD_NEW_PROPERTY,
  APP_ROUTE.AGENT_EDIT_PROPERTY,
  APP_ROUTE.AGENT_MESSAGES,
  APP_ROUTE.AGENT_MY_APPLICATION,
  APP_ROUTE.AGENT_CHAT_ROOM,
  APP_ROUTE.AGENT_CRM_CHAT_ROOM,
  APP_ROUTE.AGENT_SEND_LEASE_MY_APPLICATION,
  APP_ROUTE.VERIFY_CODE,
  APP_ROUTE.SETTINGS,
  APP_ROUTE.GENERAL_SETTINGS,
  APP_ROUTE.SUBSCRIPTION_SETTINGS,
  APP_ROUTE.SUBSCRIPTION,
  APP_ROUTE.MANUAL_VERIFICATION,
  APP_ROUTE.AI_MARKETING_SETTINGS,
  APP_ROUTE.AGENT_PUBLIC_PROFILE,
];

export const SIDE_BAR_ROUTES = [
  {
    label: 'Home',
    value: APP_ROUTE.HOME,
    icon: Home,
    isVisible: true,
    isVisibleBadge: false,
  },
  {
    label: 'Saved Listings',
    value: APP_ROUTE.SAVED_LISTING,
    icon: SavedListing,
    isVisible: true,
    isVisibleBadge: false,
  },
];

export const AGENT_ROUTES = [
  {
    label: 'Home',
    value: APP_ROUTE.AGENT_HOME,
    icon: Home,
    isVisible: true,
    isVisibleBadge: false,
  },
  {
    label: 'Explore',
    value: APP_ROUTE.EXPLORE, //TODO: ICONS need's to be update yet.
    icon: Search,
    isVisible: true,
    isVisibleBadge: false,
  },
  {
    label: 'Messages',
    value: APP_ROUTE.AGENT_MESSAGES,
    icon: Messages,
    isVisible: true,
    isVisibleBadge: true,
  },
  {
    label: 'CRM',
    value: APP_ROUTE.AGENT_DASHBOARD_CRM_MARKETING, //TODO: ICONS need's to be update yet.
    icon: MyApplication,
    isVisible: true,
    isVisibleBadge: false,
  },
  /*{
    label: 'Notifications',
    value: APP_ROUTE.AGENT_NOTIFICATIONS,
    icon: Bell,
    isVisible: true,
  },*/
];

export const RENTER_ROUTES = [
  //Explore page is replica of the home page so
  // { label: 'Home', value: APP_ROUTE.HOME, icon: Home, isVisible: true },
  {
    label: 'Explore',
    value: APP_ROUTE.EXPLORE, //TODO: ICONS need's to be update yet.
    icon: Search,
    isVisible: true,
    isVisibleBadge: false,
  },
  {
    label: 'Saved Listings',
    value: APP_ROUTE.SAVED_LISTING, //TODO: ICONS need's to be update yet.
    icon: SavedListing,
    isVisible: true,
    isVisibleBadge: false,
  },
  {
    label: 'Messages',
    value: APP_ROUTE.RENTER_MESSAGES,
    icon: Messages,
    isVisible: true,
    isVisibleBadge: true,
  },
  {
    label: 'My Application',
    value: APP_ROUTE.RENTER_MY_APPLICATION,
    icon: MyApplication,
    isVisible: true,
    isVisibleBadge: false,
  },
];

/**
 * This Array is used for setting screen's sidebar options.
 */
export const SETTINGS_SIDEBAR = () => [
  {
    label: 'Edit Profile',
    pageTitle: 'Edit Profile',
    value: APP_ROUTE.SETTINGS,
    icon: 'profile',
    isVisible: true,
    isCollapse: false,
    collapse: [],
  },
  {
    label: 'General Settings',
    pageTitle: 'General Settings',
    value: APP_ROUTE.GENERAL_SETTINGS,
    icon: 'settings',
    isVisible: true,
    isCollapse: false,
    collapse: [],
  },
  {
    label: 'Subscription',
    pageTitle: 'Subscription Management',
    value: APP_ROUTE.SUBSCRIPTION_SETTINGS,
    icon: 'profileAdd',
    isVisible:
      getUserType() === ZEME_USERS_ROLE.AGENT ||
      getUserType() === ZEME_USERS_ROLE.LANDLORD,
    isCollapse: false,
    collapse: [],
  },
  {
    label: 'AI Marketing',
    pageTitle: 'AI Marketing',
    value: APP_ROUTE.AI_MARKETING_SETTINGS,
    icon: 'aiMarketing',
    isVisible:
      getUserType() === ZEME_USERS_ROLE.AGENT ||
      getUserType() === ZEME_USERS_ROLE.LANDLORD,
    isCollapse: false,
    collapse: [],
  },
  // {
  //   label: 'Notification Settings',
  //   value: APP_ROUTE.HOME,
  //   icon: 'bell',
  //   isVisible: false,
  //   isCollapse: true,
  //   collapse: [
  //     { value: false, label: 'Email Notifications' },
  //     { value: true, label: 'SMS Notifications' },
  //     { value: true, label: 'Push Notifications' },
  //     { value: true, label: 'Set Out of Office Mode' },
  //     { value: true, label: 'Message Read Alerts' },
  //   ],
  // },
];
