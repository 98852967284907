import React from 'react';

function OwnerIcon(props: object) {
  return (
    <svg
      width="106"
      height="93"
      viewBox="0 0 106 93"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        opacity="0.8"
        d="M68.5833 66.0428H11.7036V92.2744H68.5833V66.0428Z"
        fill="#CFDFFF"
      />
      <path
        opacity="0.8"
        d="M0 66.0428L12.0286 55.389H73.8535L69.388 66.0428H0Z"
        fill="#90B2E6"
      />
      <path
        opacity="0.8"
        d="M69.5851 27.162H17.0386V60.8615H69.5851V27.162Z"
        fill="#CFDFFF"
      />
      <path
        opacity="0.8"
        d="M53.4113 34.8506H32.4971V50.1076H53.4113V34.8506Z"
        fill="#B7CCF5"
      />
      <path
        opacity="0.8"
        d="M43.6788 34.8506H41.9717V50.1076H43.6788V34.8506Z"
        fill="#90B2E6"
      />
      <path
        opacity="0.8"
        d="M53.4113 41.8982H32.4971V43.1373H53.4113V41.8982Z"
        fill="#90B2E6"
      />
      <path
        opacity="0.8"
        d="M53.4113 33.4763H32.4971V34.8505H53.4113V33.4763Z"
        fill="#90B2E6"
      />
      <path
        opacity="0.8"
        d="M32.497 33.4763H30.6006V50.1074H32.497V33.4763Z"
        fill="#90B2E6"
      />
      <path
        opacity="0.8"
        d="M55.4317 33.4763H53.4116V50.1074H55.4317V33.4763Z"
        fill="#90B2E6"
      />
      <path
        opacity="0.8"
        d="M55.4314 50.1074H30.6006V51.7292H55.4314V50.1074Z"
        fill="#90B2E6"
      />
      <path
        opacity="0.8"
        d="M83.1045 17.72L70.4271 26.8375C70.134 27.0484 69.7821 27.162 69.421 27.1622H17.9862C16.2676 27.1622 15.6078 24.9219 17.0523 23.9908L53.8316 0.275355C54.0897 0.108721 54.3877 0.0141575 54.6946 0.00146969C55.0016 -0.0112181 55.3063 0.0584306 55.5773 0.203196L78.6419 12.5219L82.9103 14.8018C83.1683 14.9398 83.387 15.1411 83.5457 15.387C83.7044 15.6328 83.7979 15.915 83.8173 16.207C83.8368 16.499 83.7815 16.7911 83.6568 17.0558C83.5321 17.3205 83.342 17.5491 83.1045 17.72Z"
        fill="#A8CBF3"
      />
      <path
        opacity="0.8"
        d="M61.5363 68.3697H14.9712V92.2744H61.5363V68.3697Z"
        fill="#E9F0FD"
      />
      <path
        opacity="0.8"
        d="M61.5363 68.3697H14.9712V69.0832H61.5363V68.3697Z"
        fill="#A1BDF4"
      />
      <path
        opacity="0.8"
        d="M61.5363 70.0156H14.9712V70.729H61.5363V70.0156Z"
        fill="#A1BDF4"
      />
      <path
        opacity="0.8"
        d="M61.5363 72.2101H14.9712V72.9234H61.5363V72.2101Z"
        fill="#A1BDF4"
      />
      <path
        opacity="0.8"
        d="M61.5363 74.4047H14.9712V75.1181H61.5363V74.4047Z"
        fill="#A1BDF4"
      />
      <path
        opacity="0.8"
        d="M61.5363 77.1478H14.9712V77.8611H61.5363V77.1478Z"
        fill="#A1BDF4"
      />
      <path
        opacity="0.8"
        d="M61.5363 79.8906H14.9712V80.6041H61.5363V79.8906Z"
        fill="#A1BDF4"
      />
      <path
        opacity="0.8"
        d="M61.5363 82.6339H14.9712V83.3473H61.5363V82.6339Z"
        fill="#A1BDF4"
      />
      <path
        opacity="0.8"
        d="M61.5363 85.377H14.9712V86.0903H61.5363V85.377Z"
        fill="#A1BDF4"
      />
      <path
        opacity="0.8"
        d="M61.5363 88.1201H14.9712V88.8334H61.5363V88.1201Z"
        fill="#A1BDF4"
      />
      <path
        opacity="0.8"
        d="M69.5851 27.162H17.0386V30.9652H69.5851V27.162Z"
        fill="#BECEF8"
      />
      <path
        opacity="0.8"
        d="M62.0502 91.4269H14.4565V67.9026H62.0502V91.4269ZM15.4852 90.4936H61.0216V68.8359H15.4852V90.4936Z"
        fill="#90B2E6"
      />
      <path
        opacity="0.8"
        d="M67.6437 29.11L59.4424 60.8615H67.6437V29.11Z"
        fill="#BECEF8"
      />
      <path
        opacity="0.8"
        d="M103.035 29.1099H67.6436V92.2746H103.035V29.1099Z"
        fill="#DCE6FF"
      />
      <path
        opacity="0.8"
        d="M104.153 59.8101H66.3794V62.8095H104.153V59.8101Z"
        fill="#A8CBF3"
      />
      <path
        opacity="0.8"
        d="M95.7346 35.869H74.8203V51.1258H95.7346V35.869Z"
        fill="#B7CCF5"
      />
      <path
        opacity="0.8"
        d="M86.0021 35.869H84.2949V51.1258H86.0021V35.869Z"
        fill="#90B2E6"
      />
      <path
        opacity="0.43"
        d="M95.7346 42.9166H74.8203V44.1556H95.7346V42.9166Z"
        fill="#90B2E6"
      />
      <path
        opacity="0.8"
        d="M95.7346 34.4947H74.8203V35.869H95.7346V34.4947Z"
        fill="#90B2E6"
      />
      <path
        opacity="0.8"
        d="M74.8203 34.4947H72.9238V51.1258H74.8203V34.4947Z"
        fill="#90B2E6"
      />
      <path
        opacity="0.8"
        d="M97.7546 34.4947H95.7344V51.1258H97.7546V34.4947Z"
        fill="#90B2E6"
      />
      <path
        opacity="0.8"
        d="M97.7548 51.1259H72.9238V52.7476H97.7548V51.1259Z"
        fill="#90B2E6"
      />
      <path
        opacity="0.8"
        d="M105.368 57.6927H64.9209V60.6923H105.368V57.6927Z"
        fill="#A1BDF4"
      />
      <path
        opacity="0.8"
        d="M83.1047 17.7201L70.4273 26.8375C70.1342 27.0484 69.7823 27.162 69.4212 27.1622H60.3398C66.5093 22.3693 72.61 17.4892 78.6421 12.522L82.9105 14.8019C83.1685 14.9398 83.3872 15.1412 83.5459 15.387C83.7046 15.6329 83.7981 15.9151 83.8175 16.207C83.837 16.499 83.7817 16.7911 83.657 17.0558C83.5323 17.3205 83.3422 17.5491 83.1047 17.7201Z"
        fill="#A1BDF4"
      />
      <path
        opacity="0.8"
        d="M67.6436 29.1099L85.1448 12.7895L103.035 29.1099H67.6436Z"
        fill="#A8CBF3"
      />
      <path
        opacity="0.8"
        d="M103.035 29.1099H67.6436V31.9344H103.035V29.1099Z"
        fill="#BECEF8"
      />
      <path
        opacity="0.8"
        d="M105.368 57.6927H64.9209V59.1925H105.368V57.6927Z"
        fill="#90B2E6"
      />
      <path
        opacity="0.8"
        d="M103.035 90.9604H11.7036V92.2746H103.035V90.9604Z"
        fill="#A1BDF4"
      />
      <path
        opacity="0.8"
        d="M100.028 51.4616H70.9473V52.7897H100.028V51.4616Z"
        fill="#F1F3FF"
      />
      <path
        opacity="0.8"
        d="M100.028 57.0616H70.9473V58.3897H100.028V57.0616Z"
        fill="#F1F3FF"
      />
      <path
        opacity="0.8"
        d="M73.4231 52.7897H71.8354V57.6388H73.4231V52.7897Z"
        fill="#F1F3FF"
      />
      <path
        opacity="0.8"
        d="M77.5379 52.7897H75.9502V57.6388H77.5379V52.7897Z"
        fill="#F1F3FF"
      />
      <path
        opacity="0.8"
        d="M81.6522 52.7897H80.0645V57.6388H81.6522V52.7897Z"
        fill="#F1F3FF"
      />
      <path
        opacity="0.8"
        d="M85.7669 52.7897H84.1792V57.6388H85.7669V52.7897Z"
        fill="#F1F3FF"
      />
      <path
        opacity="0.8"
        d="M89.8816 52.7897H88.2939V57.6388H89.8816V52.7897Z"
        fill="#F1F3FF"
      />
      <path
        opacity="0.8"
        d="M95.0247 52.7897H93.437V57.6388H95.0247V52.7897Z"
        fill="#F1F3FF"
      />
      <path
        opacity="0.8"
        d="M99.1389 52.7897H97.5513V57.6388H99.1389V52.7897Z"
        fill="#F1F3FF"
      />
    </svg>
  );
}

export default OwnerIcon;
